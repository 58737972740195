import axios from "axios";
export const sendMailOk = async( endpoint, body ) =>{
    try{
      const { data, status } = await axios.post( "https://eterna-v4-backend-841815731741.us-central1.run.app"+endpoint, body );
      return { data, status }
  } catch (err) {
    const errores ={
      mensaje: err.message,
      linea: err.stack
    }
    console.error(errores);}
  };
  